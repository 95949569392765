import React, { useState } from "react";
// @material-ui/core components
// core components

import { Card, IconButton, TextField } from "@material-ui/core";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardBody from "components/Card/CardBody.js";
import { deleteWorkstation } from "../../api/workstationApi";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DeleteDialogImprovement from "components/DeleteDialog/DeleteDialogImprovement";
import { useLoginContext } from "context/LoginContext";

export default function AreaComponentList({
  setName,
  setWorkingOurs,
  setWorkersQuantity,
  setIsOvertime,
  setOvertimeShift,
  setIsShortBrake,
  setShortBrakeShift,
  setIsRotationalSystem,
  setRotationalSystemFrequency,
  setIsRotationalEfficient,
  setWhyRotationalEfficient,
  setCommunicationFrequency,
  setIsOccupationalGymnastics,
  setIsActivitiesProcedures,
  setIsProductionsGoals,
  setIsGoalsAchieved,
  setOrganizationalObservation,
  setCognitiveObservation,
  setAirSpeedBenchmark,
  setAirSpeedAchieved,
  setIsAirSpeedAttend,
  setTemperatureBenchmark,
  setTemperatureAchieved,
  setIsTemperatureAttend,
  setNoiseBenchmark,
  setNoiseAchieved,
  setIsNoiseAttend,
  setAirHumidityBenchmark,
  setAirHumidityAchieved,
  setIsAirHumidityAttend,
  setIlluminanceBenchmark,
  setIlluminanceAchieved,
  setIsIlluminanceAttend,
  setWorkstationId,
  setRefresh,
  setEdit,
  workstations,
  setDescription,
  setConclusion,
  setObservationVerbalization,
  setIlluminanceObservation,
  setTemperatureObservation,
  setNoiseObservation,
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [idToDelete, setIdToDelete] = useState(false);

  const { isReader } = useLoginContext();

  return (
    <>
      {workstations.length > 0 &&
        workstations.map(
          ({
            name,
            working_ours,
            workers_quantity,
            is_overtime,
            overtime_shift,
            is_short_brake,
            short_brake_shift,
            is_rotational_system,
            rotational_system_frequency,
            is_rotational_efficient,
            why_rotational_efficient,
            communication_frequency,
            is_occupational_gymnastics,
            is_activities_procedures,
            is_productions_goals,
            is_goals_achieved,
            organizational_observation,
            cognitive_observation,
            air_speed_benchmark,
            air_speed_achieved,
            is_air_speed_attend,
            temperature_benchmark,
            temperature_achieved,
            is_temperature_attend,
            noise_benchmark,
            noise_achieved,
            is_noise_attend,
            air_humidity_benchmark,
            air_humidity_achieved,
            is_air_humidity_attend,
            illuminance_benchmark,
            illuminance_achieved,
            is_illuminance_attend,
            description,
            conclusion,
            observation_verbalization,
            id,
            illuminance_observation,
            temperature_observation,
            noise_observation,
          }) => {
            const handleOpenDialog = () => {
              setIdToDelete(id);
              setOpenDialog(true);
            };

            const handleEdit = () => {
              setWorkstationId(id);
              setName(name);
              setWorkingOurs(working_ours);
              setWorkersQuantity(workers_quantity);
              setIsOvertime(is_overtime);
              setOvertimeShift(overtime_shift);
              setIsShortBrake(is_short_brake);
              setShortBrakeShift(short_brake_shift);
              setIsRotationalSystem(is_rotational_system);
              setRotationalSystemFrequency(rotational_system_frequency);
              setIsRotationalEfficient(is_rotational_efficient);
              setWhyRotationalEfficient(why_rotational_efficient);
              setCommunicationFrequency(communication_frequency);
              setIsOccupationalGymnastics(is_occupational_gymnastics);
              setIsActivitiesProcedures(is_activities_procedures);
              setIsProductionsGoals(is_productions_goals);
              setIsGoalsAchieved(is_goals_achieved);
              setOrganizationalObservation(organizational_observation);
              setCognitiveObservation(cognitive_observation);
              setAirSpeedBenchmark(air_speed_benchmark);
              setAirSpeedAchieved(air_speed_achieved);
              setIsAirSpeedAttend(is_air_speed_attend);
              setTemperatureBenchmark(temperature_benchmark);
              setTemperatureAchieved(temperature_achieved);
              setIsTemperatureAttend(is_temperature_attend);
              setNoiseBenchmark(noise_benchmark);
              setNoiseAchieved(noise_achieved);
              setIsNoiseAttend(is_noise_attend);
              setAirHumidityBenchmark(air_humidity_benchmark);
              setAirHumidityAchieved(air_humidity_achieved);
              setIsAirHumidityAttend(is_air_humidity_attend);
              setIlluminanceBenchmark(illuminance_benchmark);
              setIlluminanceAchieved(illuminance_achieved);
              setIsIlluminanceAttend(is_illuminance_attend);
              setDescription(description);
              setConclusion(conclusion);
              setObservationVerbalization(observation_verbalization);
              setIlluminanceObservation(illuminance_observation);
              setTemperatureObservation(temperature_observation);
              setNoiseObservation(noise_observation);

              setEdit(true);
            };

            return (
              <div key={id} style={{ marginTop: "10px", marginBottom: "20px" }}>
                <Card>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                          <GridItem xs={12} sm={10} md={10}>
                            <div style={{ marginTop: "10px" }}>
                              <TextField
                                label="Nome"
                                variant="outlined"
                                disabled
                                value={name}
                                fullWidth
                              ></TextField>
                            </div>
                          </GridItem>

                          <GridItem xs={12} sm={1} md={1}>
                            <div
                              style={{ padding: "10px", textAlign: "center" }}
                            >
                              <IconButton
                                color="primary"
                                edge="end"
                                aria-label="delete"
                                onClick={handleEdit}
                              >
                                <EditIcon />
                              </IconButton>
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={1} md={1}>
                            <div
                              style={{ padding: "10px", textAlign: "center" }}
                            >
                              <IconButton
                                disabled={isReader}
                                color="secondary"
                                edge="end"
                                aria-label="delete"
                                onClick={handleOpenDialog}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                              <DeleteDialogImprovement
                                open={openDialog}
                                setOpen={setOpenDialog}
                                deleteFunction={deleteWorkstation}
                                idToDelete={idToDelete}
                                setRefresh={setRefresh}
                              />
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </div>
            );
          }
        )}
    </>
  );
}
