import axios from "axios";
import { API_URL } from "./urlApi.js";
async function getReport({
  areaId,
  stepId,
  sectorId,
  gheId,
  companyId,
  jobPositionId,
  activityId,
  workstationId,
  isCover,
  isSummary,
  isStep,
  isBB,
  isRecommendation,
}) {
  try {
    const res = await axios.post(
      `${API_URL}/exportdata/report`,
      {
        area_id: areaId,
        sector_id: sectorId,
        ghe_id: gheId,
        company_id: companyId,
        job_position_id: jobPositionId,
        activity_id: activityId,
        workstation_id: workstationId,
        step_id: stepId,
        is_cover: isCover,
        is_step: isStep,
        is_summary: isSummary,
        is_bb: isBB,
        is_recommendation: isRecommendation,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("TOKEN_KEY")}`,
        },
        responseType: "blob", // Recebe a resposta como um blob
      }
    );

    return openHtmlInNewTab(res);
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    }
  }
  return;
}

export { getReport };

// const downloadFile = (response, original) => {
//   const blob = new Blob([response.data], {
//     type: "application/pdf",
//   });
//   saveAs(blob, "Relatorio_ErgoVale.pdf");
// };

const openHtmlInNewTab = (response) => {
  response.data.text().then((html) => {
    const win = window.open("");
    win.document.write(html);
    win.document.close(); // Certifique-se de que o documento é renderizado corretamente
  });
};
